import React, { useState } from "react"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"

//import css
import "./../../styles/free-tools.css"
import "./../../styles/products.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const FreeTools = ({ data }) => (
  <Layout>
    <SEO
      title="Free Tools"
      description="Test the availability of your website for free"
      pathname={`/freeTools`}
    />
    {/* HEADER  */}
    <HeadingSection />
    <FreeTools_section />
    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

/**
 *
 * @param {*} param0
 * @returns
 */
const HeadingSection = ({ data }) => {
  return (
    <div className="u-features freetools-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
          Free Website Uptime Test
        </h1>
        <p className="u-features__heading-title">
          Is your website available worldwide? Let’s find out!
        </p>
      </div>
    </div>
  )
}

const FreeTools_section = () => {
  const [pingsRegionsResults, setPingsRegionsResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [error, setError] = useState(null);
  const [testUrl, setTestUrl] = useState("");
  const [region, setRegion] = useState('All Checkpoints');

  const handleInputChange = (event) => {
    setTestUrl(event.target.value);
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value); 
  };

  const getRegionName = (region) => {
    const regionMap = {
      NVirginia: "North Virginia",
      NCalifornia: "North California",
      CTown: "Cape Town",
      HKong: "Hong Kong",
      SPaulo: "Sao Paulo"
    };
    return regionMap[region] || region;
  }

  const handleStartTest = async () => {
    if (!testUrl) {
      setError("Please enter a valid URL");
      return;
    }

    setLoading(true);
    setDisableButton(true);
    setError(null);

    try {
      const response = await fetch(`http://127.0.0.1:5000/v1/monitoring/test-ping?url=${testUrl}&region=${region}`)
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setPingsRegionsResults(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="freeTools" className="u-features freetools-list">
      <div className="wrapperContainer">
        <div className="free-ping-test">
        <span className="test-label">I want to test</span>
        <input 
            type="text" 
            value={testUrl} 
            onChange={handleInputChange} 
            placeholder="Enter URL to test"
            className="test-url-input" 
          />
        <span className="test-label">from</span>
        <select className="region-select" value={region} onChange={handleRegionChange}>
            <option value="All Checkpoints">All Regions</option>
            <option value="North America">North America</option>
            <option value="Europe">Europe</option>
            <option value="Africa">Africa</option>
            <option value="Asia">Asia</option>
            <option value="Australia">Australia</option>
            <option value="South America">South America</option>
            <option value="Middle East">Middle East</option>
          </select>
          <button className="start-test-button" onClick={handleStartTest} disabled={disableButton}>Start test</button>
        </div>

        {loading && <div>Loading results...</div>}
        {error && <div>Error: {error}</div>}
        {!loading && !error && Object.keys(pingsRegionsResults).length > 0 && (
          <div className="regions-displayed">
            {Object.keys(pingsRegionsResults).map((region, index) => (
              <div key={index} className={`ping-item_wrapper ${pingsRegionsResults[region].ok ? "available" : "unavailable"}`}>
              <label className="ping-item">
                <div className="ping-header">
                <div className={`ping-region ${pingsRegionsResults[region].ok ? "success" : "error"}`}>
                    <span className="status-icon">{pingsRegionsResults[region].ok ? '✅' : '❌'}</span>
                    <strong>{getRegionName(region)}</strong>
                  </div>
                  <div className="ping-status">
                    <p className={pingsRegionsResults[region].ok ? "available-text" : "unavailable-text"}>
                    {pingsRegionsResults[region].ok ? "Website is available" : "Website is unavailable"}</p>
                  </div>
                </div>
                <div className="ping-info">
                <p>
    <div className="resolve-box"></div>
    <strong>Resolve Time:</strong> {pingsRegionsResults[region].resolveTime} seconds
  </p>
  <p>
    <div className="connection-box"></div>
    <strong>Connection Time:</strong> {pingsRegionsResults[region].connectionTime} seconds
  </p>
  <p>
    <div className="download-box"></div>
    <strong>Download Time:</strong> {pingsRegionsResults[region].downloadTime} seconds
  </p>
 <hr />
                  <p><strong>Total Response Time:</strong> {pingsRegionsResults[region].responseTime} seconds</p>
                </div>
                <div className="ping-time-time">
                <div className="ping-bar-container">
                  <div
                    className="ping-bar"
                    style={{ width: `${Math.min((pingsRegionsResults[region].responseTime / 1) * 100, 100)}%` }}
                  ></div>
                </div>
                <strong>{pingsRegionsResults[region].responseTime}s</strong>
                </div>
              </label>
            </div>
            
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default FreeTools
